// app.js

import $ from 'jquery';
import 'what-input';
import 'imagesloaded';
import { gsap } from "gsap";
import matchHeight from 'jquery-match-height';

import Util from './module/Util';
const util = new Util();

import Menu from './module/Menu';
new Menu;

import Slider from './module/Slider';
let mySlider = new Slider();


// Cursor
import Cursor from './module/Cursor';
const cursor = new Cursor(document.querySelector('.cursor'));
_cursorEvent();


// Barba
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

barba.use(barbaPrefetch);
barba.init({
  transitions: [{
    leave(data) {
      document.body.classList.remove('menu--open');
      return gsap.to(data.current.container, {
        opacity: 0
      });
    },
    beforeEnter() {
      const scrollElem = document.scrollingElement || document.documentElement;

      if( window.location.hash.length > 0 ){
        let offset = document.querySelector(window.location.hash).offsetTop;
        scrollElem.scrollTop = offset;
      }
      else {
        scrollElem.scrollTop = 0;
      }
    },
    enter(data) {
      return gsap.from(data.next.container, {
        opacity: 0
      })
    }
  }],
  views: [
    {
      namespace: 'home',
      beforeEnter() {
        mySlider.init();

        _resizeHdl(util.is.tab_p);
        $(window).on('resize.home', function () {
          _resizeHdl(util.is.tab_p);
        });
      },
      leave() {
        $(window).off('resize.home');
        mySlider.destroy();
      }
    },
    {
      namespace: 'post-type-archive',
      beforeEnter() {
        if( !util.is.tab_l ){
          document.querySelector('.g-headerTitle').style.opacity = 0;
          document.querySelector('.g-sideNav').style.opacity = 0;
        }
      },
      afterLeave() {
        if( !util.is.tab_l ){
          document.querySelector('.g-headerTitle').style.opacity = 1;
          document.querySelector('.g-sideNav').style.opacity = 1;
        }
      }
    },
    {
      namespace: 'single',
      beforeEnter() {
        document.querySelector('.g-headerTitle').style.opacity = 0;
        document.querySelector('.g-sideNav').style.opacity = 0;
      },
      afterLeave() {
        document.querySelector('.g-headerTitle').style.opacity = 1;
        document.querySelector('.g-sideNav').style.opacity = 1;
      }
    }
  ]
});

barba.hooks.afterLeave((data) => {
  var nextHtml = data.next.html;
  var response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml)
  var bodyClasses = $(response).filter('notbody').attr('class');
  
  $('body').attr('class', bodyClasses).addClass('page--show');

  document.querySelector('.g-headerTitle').style.opacity = 1;
  document.querySelector('.g-sideNav').style.opacity = 1;
});

barba.hooks.enter((data) => {
  _cursorEvent();
});

function _cursorEvent(){
  [...document.querySelectorAll('a')].forEach(el => {
    el.addEventListener('mouseenter', () => cursor.emit('enter'));
    el.addEventListener('mouseleave', () => cursor.emit('leave'));
  });
}

function _resizeHdl(browser) {
  if (browser) {
    let mainVisualH = $('.p-mainvisual').innerHeight();

    $('.p-mainvisualSliderItem').each(function () {
      let $this = $(this);
      let $mainImage = $('.p-mainvisualImage', $this);
      let $mainImageSvg = $mainImage.find('svg');

      let $mainText = $('.p-mainvisualDescription', $this);
      let $mainBtn = $('.p-mainvisualBtn', $this);

      let tH = window.innerHeight - parseInt($mainText.innerHeight()) - parseInt($mainBtn.innerHeight()) - 70;

      $mainImageSvg.css({
        width: 1040 / 584 * tH,
        height: tH
      });
    });
  }
}

if( window.location.hash.length > 0 ){
  let offset = document.querySelector(window.location.hash).offsetTop;
  setTimeout(function(){
    window.scrollTo(0,offset);
  }, 300);
}

$(function () {
  $('.archiveTitle').matchHeight(4);
  $('.c-articleListTitle').matchHeight(3);
});

$(window).on('scroll', function () {
  if ($(window).scrollTop() > window.innerHeight) {
    if (!$('body').hasClass('top--scrolled')) {
      $('body').addClass('top--scrolled');
    }
  } else {
    $('body').removeClass('top--scrolled');
  }

  if ($(window).scrollTop() > 10) {
    if (!$('body').hasClass('few--scrolled')) {
      $('body').addClass('few--scrolled');
    }
  } else {
    $('body').removeClass('few--scrolled');
  }
});