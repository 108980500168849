// Slider

'use strict'

import $ from "jquery";
import slick from "slick-carousel";

export default class {
  constructor(){
    // this.init();
  }

  init(){
    let me = this;

    me.options = {
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
      arrows: false,
      dots: false,
      pauseOnHover: false,
      pauseOnFocus: false
    };

    me.$mainslider = $('.p-mainvisualSlider').slick(me.options);
    
    me.play();
  }

  play(){
    let me = this;

    $('.p-mainvisualSlider').find('[data-slick-index="0"]').addClass('animation');

    me.$mainslider.on('beforeChange', function(e, slick, currentSlide, nextSlide){
      $('.p-mainvisualSlider').find('[data-slick-index="'+currentSlide+'"]').removeClass('animation');
      $('.p-mainvisualSlider').find('[data-slick-index="'+nextSlide+'"]').addClass('animation');
    });

    let $mainnav = $('.p-mainvisualNav');
    $mainnav.find('button').on('click', function(){
      let index = $(this).index();
      me.$mainslider.slick('slickGoTo', index);
    });
  }

  destroy(){
    let me = this;

    console.log(destroy)

    me.$mainslider.slick('unslick');
    me.$mainslider.off('beforeChange');
    $('.p-mainvisualSlider').find('.slick-slide').removeClass('animation');
    $mainnav.find('button').off('click');
  }
}